import React from "react";
import ContactUs from "../components/ContactUs/ContactUs";
import ContactUsForm from "../components/ContactUsForm/ContactUsForm";
import FooterHome from "../components/Footer/FooterHome";
import FooterLower from "../components/Footer/FooterLower";
import MapHome from "../components/MapHome/MapHome";

function Contact() {
  return (
    <div>
      <div>
        <div>
          <ContactUs />
          <ContactUsForm />
          <MapHome />
          <FooterHome />
          {/* <FooterLower /> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
