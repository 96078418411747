import React from "react";
import "./ServicesCompany.css";

function ServicesCompany() {
  return (
    <div className="servicesCompany">
      <div className="d-flex">
        <div className="servicesCompany1 w-50">
          <div className="bg-dull">
            <div className="content">
              <h2>MFS-FRA Express</h2>
              <p>Germany & Europe</p>
            </div>
          </div>
        </div>
        <div className="servicesCompany2 w-50">
          <div className="bg-dull">
            <div className="content">
              <h2>Express special trips</h2>
              <p>urgent shipments</p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="servicesCompany3 w-50">
          <div className="bg-dull">
            <div className="content">
              <h2>Fast Courier</h2>
              <p>We support the Fast Courier</p>
            </div>
          </div>
        </div>
        <div className="servicesCompany4 w-50">
          <div className="bg-dull">
            <div className="content">
              <h2>OBC (On Board Courier)</h2>
              <p>Your tool for emergency logistics.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCompany;
