import React from "react";
import "./CompanyContent.css";

function CompanyContent() {
  return (
    <div>
      <div className="container CompanyContent">
        <div className="responsive">
          <h2>WHO WE ARE</h2>
          <p className="py-5">
            Our customers are our priority and we deem the responsibility to
            serve them better as important as it is for them. Our
            state-of-the-art systems make sure that our staff has got the
            appropriate technical understanding of the job, so our customers get
            a reliable, efficient and secure service. From start till end of
            your inquiry and order booking, our customer-friendly service is
            there for you, providing a clean & transparent invoicing.
            <br /> <br /> Have we miss something or you want to ask anything
            about us or our services, please do let us know through our contact
            form. We look forward to your valuable feedback and questions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyContent;
