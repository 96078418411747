import React from "react";
import FooterLower from "../components/Footer/FooterLower";
import ServicesServices from "../components/ServicesServices/ServicesServices";
import ServicesCategories from "../components/ServicesCategories/ServicesCategories";
import GpsTracking from "../components/GpsTracking/GpsTracking";
import ServicesContent from "../components/ServicesContent/ServicesContent";
import FooterHome from "../components/Footer/FooterHome";

function Services() {
  return (
    <div>
      <ServicesContent />
      <ServicesCategories />
      <ServicesServices />
      <GpsTracking />
      <FooterHome />
      {/* <FooterLower /> */}
    </div>
  );
}

export default Services;
