import React from "react";

function ServicesServices() {
  return (
    <div className="ServicesServices">
      <div className="d-flex">
        <div className="supportLeft w-50">
          <div className="bg-dull">
            <div className="content">
              <h3>Highly Competent Trade Partner</h3>
              <p>
                No matter what is the size of your package or what are the
                contents of your package, we keep your delivery discreet and
                uncompromised, anywhere in the European region.
              </p>
            </div>
          </div>
        </div>
        <div className="supportRight w-50">
          <div className="bg-dull">
            <div className="content">
              <h3>Advance Tracking System</h3>
              <p>
                We provide a state-of-the-art tracking system allowing you to
                save time & money by easily tracking your shipment; what route
                it took and where it is currently reached.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesServices;
