import React from "react";
import "./FooterHome.css";
import { BiMap } from "react-icons/bi";
import { MdOutlineCall } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import FooterLower from "./FooterLower";
import { Link } from "react-router-dom";

function FooterHome() {
  return (
    <div className="footer">
      <div className="footerUpper p-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12 col-sm-6">
              <h3>Services</h3>
              <ul>
                <li>
                  <Link to="/services">MFS-FRA</Link>
                </li>
                <li>
                  <Link to="/services">Transport of Goods</Link>
                </li>
                <li>
                  <Link to="/services">GPS Tracking</Link>
                </li>
                <li>
                  <Link to="/services">Fast Courier</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xs-12 col-sm-6">
              <h3>Info</h3>
              <ul>
                {/* <li>
                  <a href="#">Fleet</a>
                </li> */}
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xs-12 col-sm-12">
              <h3>Office</h3>
              <ul>
                <li>
                  <a
                    href="https://goo.gl/maps/swZAy7NEknDZBG8M8"
                    className="d-flex"
                  >
                    <BiMap className="footer-icons" />
                    Cimbernweg 49 65929 Frankfurt am Main
                  </a>
                </li>
                <li>
                  <a
                    href="tel:+491795143709"
                    className="d-flex align-items-center"
                  >
                    <MdOutlineCall className="footer-icons" />
                    +49 1795 143709
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <a href="mailto:abrar@mfs-fra.com">
                    <BiMailSend className="footer-icons" />
                    abrar@mfs-fra.com
                  </a>
                </li>
                <li className="Social pt-4">
                  <a href="https://www.facebook.com/Mfs-Fra-102578022708226/?_rdc=1&_rdr">
                    FACEBOOK
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterLower />
    </div>
  );
}

export default FooterHome;
