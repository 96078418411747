import React from "react";
import "./SupportHome.css";

function SupportHome() {
  return (
    <div className="SupportHome">
      <div className="d-flex">
        <div className="supportLeft w-50">
          <div className="bg-dull">
            <div className="content">
              <h3>24/7 support</h3>
              <p>
                We are a specialized and dedicated firm with state of the
                systems which enables our customers enhance and maintain
                distribution of their products worldwide.
              </p>
            </div>
          </div>
        </div>
        <div className="supportRight w-50">
          <div className="bg-dull">
            <div className="content">
              <h3>MFS-FRA Rides</h3>
              <p>
                Our business also offers private chauffeur-driven cab services
                throughout Germany. Our highly skilled & experienced drivers are
                well versed with the road safety & traffic guidelines enabling
                them to keep our customers safe and well on-time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportHome;
