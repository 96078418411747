import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: "440px",
      }}
    >
      <div className="text-center">
        <p style={{ fontSize: "126px", fontWeight: "bold" }}>404</p>
        <br />
        <p className="m-0 p-0" style={{ fontSize: "28px" }}>
          Page Not Found
        </p>
        <br />
        <Link to="/">
          <button type="button" className="btn btn-outline-dark">
            Back Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
