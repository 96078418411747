import React from "react";
import "./ServicesHome.css";
import { BsBoxSeam, BsInboxesFill } from "react-icons/bs";
import { CgScreen } from "react-icons/cg";
import { MdOutlineContactPhone } from "react-icons/md";
import { Link } from "react-router-dom";

function ServicesHome() {
  return (
    <div className="services py-5">
      <div className="container">
        <h3>Our Facilites</h3>
        <div className="row row-cols-lg-4 row-cols-md-4 row-cols-xs-1">
          <div className="col col-xs-12 col-sm-12">
            <div className="card">
              <BsBoxSeam className="card-icon" />
              <div className="card-body">
                <Link to="/contact">
                  <h6>Transport of Goods</h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <BsInboxesFill className="card-icon" />
              <div className="card-body">
                <Link to="/contact">
                  <h6>Fast Courier</h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <CgScreen className="card-icon" />
              <div className="card-body">
                <Link to="/contact">
                  <h6>GPS Tracking</h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <MdOutlineContactPhone className="card-icon" />
              <div className="card-body">
                <Link to="/contact">
                  <h6>24/7 Support</h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesHome;
