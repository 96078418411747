import React from "react";
import "./FooterHome.css";

function FooterLower() {
  return (
    <div>
      <div className="footerLower py-3">
        <div className="container text-start d-flex">
          MFS-FRA © 2022 Powered by{" "}
          <a
            href="http://contactus.optiwisesolutions.com/" className="opt-hov"
            style={{ marginLeft: "4px" }}
          >
            {" "}
            Optiwise Solutions
          </a>
          {/* <a href="#" className="mx-3">Imprint </a>
      |
      <a href="#" className="mx-3">Privacy </a> */}
        </div>
      </div>
    </div>
  );
}

export default FooterLower;
