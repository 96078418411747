import React from "react";
import "./Testimonal.css";
import user from "../../assets/images/user.png";

function Testimonal() {
  return (
    <div className="container testimonal py-5">
      <h3>Customer Opinions</h3>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="d-flex">
              <div className="mx-4 border-radius-50">
                <img
                  src={user}
                  alt="First slide"
                  width={"150px"}
                  height={"150px"}
                />
                <h3>Benjamin S</h3>
              </div>
              <div className="w-75 text-justify">
                <h3>
                  <i class="bi bi-quote"></i>
                </h3>
                <p>
                  "Thank you for the competent service and friendly demeanor.”
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex">
              <div className="mx-4 border-radius-50">
                <img
                  src={user}
                  alt="First slide"
                  width={"150px"}
                  height={"150px"}
                />
                <h3>Amanda M</h3>
              </div>
              <div className="w-75 text-justify">
                <h3>
                  <i class="bi bi-quote"></i>
                </h3>
                <p>
                  "Recently had 2 deliveries by MFS-FRA. Very impressed by the
                  helpful updates using text and email. Driver was friendly and
                  helpful."
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex">
              <div className="mx-4 border-radius-50">
                <img
                  src={user}
                  alt="First slide"
                  width={"150px"}
                  height={"150px"}
                />

                <h3>Brandon R</h3>
              </div>
              <div className="w-75 text-justify">
                <h3>
                  <i class="bi bi-quote"></i>
                </h3>
                <p>
                  "I'd just like to thank you for an excellent service. We often
                  have issues with deliveries as our house is on a country lane,
                  but your couriers arrived as promised, they were friendly and
                  overall this was a very efficient service. Thank you ."
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Testimonal;
