import React from "react";
import "./MapHome.css";

function MapHome() {
  return (
    <section className="maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.3714966866637!2d8.539970000000002!3d50.116769999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bda0b52b5770e1%3A0xf634ab4be8996d77!2sCimbernweg%2049%2C%2065929%20Frankfurt%20am%20Main%2C%20Germany!5e0!3m2!1sen!2s!4v1670668690618!5m2!1sen!2s"
        width="100%"
        height="456"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
}

export default MapHome;
