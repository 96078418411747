import React from "react";
import "./ContactUsForm.css";

function ContactUsForm() {
  return (
    <div className="ContactUsForm container">
      <div className="cantactUsContainer">
        <h2 className="pb-4">Contact</h2>
        <div className="row">
          <div className="col">
            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Surname:"
            />
          </div>
          <div className="col">
            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="E-mail:"
            />
          </div>
          <div className="col">
            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Phone:"
            />
          </div>
        </div>
        <div className="mt-4">
        <textarea
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="8"
        placeholder="News:"
      ></textarea>
        </div>
        <div className="SendButton">
            <a href="#">SEND</a>
          </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
