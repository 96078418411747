import React from "react";
import CarouselForm from "../CarouselForm";
import { Link } from "react-router-dom";
import "./mainHome.css";

function MainHome() {
  return (
    <div className="main">
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner ">
          <div className="carousel-item active item1">
            <div className="">
              <div className="container ">
                <div className="row row-cols-1 row-cols-md-2 align-items-center">
                  <div className="col">
                    <div className=" text-start main-content">
                      <h2>
                        <b>MFS-FRA</b>
                      </h2>
                      {/* <h3 className="py-3">Aircraft on Ground</h3> */}
                      <h4 className="pb-3">Express Delivery Services</h4>
                      <Link to="/contact">Book Now</Link>
                    </div>
                  </div>
                  <div className="col">
                    <CarouselForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item item2 ">
            <div className="container ">
              <div className="row row-cols-1 row-cols-md-2 align-items-center">
                <div className="col">
                  <div className=" text-start main-content">
                    <h2 className="text-dark-mob">
                      <b>MFS-FRA</b>
                    </h2>
                    {/* <h3 className="py-3">Aircraft on Ground</h3> */}
                    <h4 className="pb-3 text-dark-mob">
                      Fast, Secure and Reliable
                    </h4>
                    <Link to="/contact">Book Now</Link>
                  </div>
                </div>
                <div className="col">
                  <CarouselForm />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item item3">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 align-items-center">
                <div className="col">
                  <div className=" text-start main-content">
                    <h2>
                      <b>
                        Our Customers, <br /> Our Priority
                      </b>
                    </h2>
                    {/* <h3 className="py-3">Aircraft on Ground</h3> */}
                    {/* <h4 className="pb-3">Day and night express services</h4> */}
                    <Link to="/contact">Book Now</Link>
                  </div>
                </div>
                <div className="col">
                  <CarouselForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" carousel-control-buttons">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MainHome;
