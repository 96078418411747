import React from "react";
import "./NavBar.css";
import { Link, NavLink } from "react-router-dom";
import { BsListNested, BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineArrowBack, MdOutlineCall } from "react-icons/md";

function OffCanvas() {
  return (
    <div className="offCanvas w-100">
      <div className="d-flex justify-content-between px-3">
        <a
          class="offCanvasnav"
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <BsListNested />
        </a>
        <a
          class="offCanvasnav"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExampleContact"
          aria-controls="offcanvasExampleContact"
        >
          <BsThreeDotsVertical />
        </a>
      </div>
      <div
        class="offcanvas offcanvas-end"
        tabindex="1"
        id="offcanvasExampleContact"
        aria-labelledby="offcanvasExampleContact"
      >
        <div className="d-flex justify-content-between align-items-center offcanvas-end">
          <a to="tel:+49 1795 143709" className="d-flex align-items-center ">
            <MdOutlineCall className="footer-icons mx-2" />
            +49 1795 143709s
          </a>
          <a
            type="button"
            className=" text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <BsThreeDotsVertical />
          </a>
        </div>
      </div>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <ul className="navbar-nav mb-2">
            <li
              className="nav-item "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "activeNav nav-link" : "inactive nav-link"
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li
              className="nav-item ms-3 "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "activeNav nav-link" : "inactive nav-link"
                }
                to="/company"
              >
                Company
              </NavLink>
            </li>
            <li
              className="nav-item ms-3"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "activeNav nav-link" : "inactive nav-link"
                }
                to="/services"
              >
                Services
              </NavLink>
            </li>
            <li
              className="nav-item ms-3"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "activeNav nav-link" : "inactive nav-link"
                }
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <a
            type="button"
            class=" text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <MdOutlineArrowBack />
          </a>
        </div>
      </div>
    </div>
  );
}

export default OffCanvas;
