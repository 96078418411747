import React from "react";
import MainHome from "../components/mainHome/MainHome";
import Quotation from "../components/quotation/Quotation";
import ServicesHome from "../components/ServicesHome/ServicesHome";
import SupportHome from "../components/supportHome/SupportHome";
import MapHome from "../components/MapHome/MapHome";
import FooterHome from "../components/Footer/FooterHome";
import CarouselForm from "../components/CarouselForm";
import FooterLower from "../components/Footer/FooterLower";

function Home() {
  return (
    <div>
      <MainHome />
      <ServicesHome />
      <Quotation />
      <SupportHome />
      <MapHome />
      <FooterHome />
      {/* <FooterLower /> */}
    </div>
  );
}

export default Home;
