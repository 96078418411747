import React from "react";
import { BiMap } from "react-icons/bi";
import { MdOutlineCall } from "react-icons/md";
import { BiTime, BiMailSend } from "react-icons/bi";
import "./ContactUs.css";
import ContactUsForm from "../ContactUsForm/ContactUsForm";

function ContactUs() {
  return (
    <div>
      <div className="container contactUs ">
        <h2 className="py-3">Contact</h2>
        <div>
          <div className="row text-start">
            <div className="col">
              <h3>Office Address</h3>
              <ul>
                <li>
                  <a
                    href="https://goo.gl/maps/swZAy7NEknDZBG8M8"
                    // className="d-flex"
                  >
                    <BiMap className="footer-icons" />
                    Cimbernweg 49 65929 Frankfurt am Main
                  </a>
                </li>
                <li>
                  <a href="tel:+491795143709" className="align-items-center">
                    <MdOutlineCall className="footer-icons" />
                    +49 1795 143709
                  </a>
                </li>
                <li className="align-items-center">
                  <a href="mailto:abrar@mfs-fra.com">
                    <BiMailSend className="footer-icons" />
                    abrar@mfs-fra.com
                  </a>
                </li>
                <li>
                  <BiTime className="footer-icons" />
                  24/7 Support
                </li>
              </ul>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
