import React from "react";
import CompanyContent from "../components/CompanyContent/CompanyContent";
import FooterHome from "../components/Footer/FooterHome";
import FooterLower from "../components/Footer/FooterLower";
import ServicesCompany from "../components/ServicesCompany/ServicesCompany";
import Testimonal from "../components/testimonals/Testimonal";
import Unique from "../components/Unique/Unique";

function Company() {
  return (
    <div>
      <CompanyContent />
      <ServicesCompany />
      <Testimonal />
      <Unique />
      <FooterHome />
      {/* <FooterLower /> */}
    </div>
  );
}

export default Company;
