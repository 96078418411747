import React from "react";
import "./Unique.css";

function Unique() {
  return (
    <div>
      <div className="Unique text-start">
        <div className="container">
          <div>
            <h2 className="py-2">Why Choose Us?</h2>
            <h3 className="py-2">Highly Professional Team</h3>
            <p className="py-2">MFS-FRA believes attention to detail and efficiency is the key to success.</p>
          </div>
          <div>
            <h3 className="py-2">Personalized & Dedicated Support</h3>
            <p className="py-2">We know every customer and each job has different prospects and needs dedicated support.</p>
          </div>
          <div>
            <h3 className="py-2">Fast & Secure</h3>
            <p className="py-2">Our highly skilled team make sure on-time deliveries in a safe & secure manner.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unique;
