  import React from "react";
import "./ServicesContent.css";

function ServicesContent() {
  return (
    <div className="ServicesContent">
      <div className="container responsive">
        <div className="py-5">
          <h2>WHAT WE DO?</h2>
          <p className="pt-5">
            We also offer you: veterinary inspection, load monitoring, document
            service, outsourcing and delivery, direct and special trips
            throughout Germany and Europe, weekend and holiday service,
            scheduled delivery, special transport, return transport, on-board
            courier, refrigerated transport, courier service and overnight
            service.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServicesContent;
