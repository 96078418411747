import React from "react";
import { Link } from "react-router-dom";
import "./Quotation.css";

const Quotation = () => {
  return (
    <div>
      <div className="container">
        <div className="quotation">
          <h2>Quotation request</h2>
          <h3>
            Let us know your requirements and we will provide you a personalized
            offer for you.
          </h3>
          <div>
            <Link to="/contact">INQUIRY</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;
