import React from "react";
import "./ServicesCategories.css";

function ServicesCategories() {
  return (
    <div className="ServicesCategories">
      <div className="container py-5">
        <div class="row row-cols-1 row-cols-md-3 g-3 justify-content-around">
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/airport_transfer.jpg")}
                alt="airport_transfer"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Airport Transfers</h5>
                <p class="card-text">
                  Planning a flight schedule? Whether you are flying back or
                  flying outside your region, we can provide you fast, secure &
                  reliable Airport Transfer ride. We regularly keep up with the
                  flight schedules, so our customers don’t have to wait.
                </p>
              </div>
            </div>
          </div>
          <div class="col ">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/train_station_transfer.webp")}
                alt="train_station_transfer"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Train Station Transfers</h5>
                <p class="card-text">
                  We will pick you up from or drop you at your chosen train
                  station. We are fully coordinated with the Train Schedules,
                  which enables us to provide on-time train station transfer
                  services to keep our customers reach their destination
                  on-time.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/city_​​drive_1.jpg")}
                alt="city_​​drive"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">City Drive</h5>
                <p class="card-text">
                  Wherever you want to travel with-in the city of Eppstein or
                  its surrounding areas, MFS-FRA provides you quick, fast and
                  reliable cab ride. Our skilled drivers knows every possible
                  pathway to keep you on-time taking you through the shortest &
                  safest possible way.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/courier_trips.avif")}
                alt="courier_trips"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Courier Trips</h5>
                <p class="card-text">
                  Submitting documents or sending valuable goods to someone? We
                  provide around the clock express delivery service enabling us
                  to offer you an uncompromised discreet courier dispatch. We
                  are fast, secure and reliable courier & cargo providers.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/disco_transfer.jpg")}
                alt="disco_transfer"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Disco Transfers</h5>
                <p class="card-text">
                  You don’t need to wait any longer to reach your favorite
                  discotheque or event. Book a ride with us and avoid waiting at
                  Bus Stops, Taxi Stands or Train Stations to reach your
                  favorite place. We provide secure and reliable, quick private
                  cab services.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/shopping_service.jpg")}
                alt="shopping_service"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Shopping Service</h5>
                <p class="card-text">
                  Planning a shopping? Sure, it’s a hectic exercise waiting for
                  parking spaces or a taxi to take you to your shopping
                  expedition. Book a private cab services with us and keep your
                  shopping time hassle-free with no-worries of holding shopping
                  bags while waiting for a ride.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/long_distance_trips.jpg")}
                alt="long_distance_trips"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Long-distance Trips</h5>
                <p class="card-text">
                  Whether you want to just tour another city or you want to meet
                  some relatives or you need to reach a meeting in some other
                  city, we are here for you. Frankfurt, Munich, Hamburg or any
                  other city in Germany, our duly maintained cars are the most
                  secure & reliable ride for you.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                class="card-img-top"
                src={require("../../assets/images/services/other_goals.jpg")}
                alt="other_goals"
                height="230px"
              />
              <div class="card-body">
                <h5 class="card-title">Other Goals</h5>
                <p class="card-text">
                  Didn’t find what you are looking for? Don’t worry, because our
                  highly skilled and most trusted chauffeur-driven private cab
                  service can easily fulfil your requirements. Our pride is
                  punctuality and our priority is our customer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCategories;
