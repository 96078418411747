import React from "react";
import "./GpsTracking.css";
import tracking from "../../assets/images/memberof.png";
function GpsTracking() {
  return (
    <div>
      <div className="container py-5 GpsTracking">
        <div className="py-4">
          <h2>GPS Tracking</h2>
        </div>
        <div>
          <p className="text-justify">
            Our modern and advance GPS Tracking Solution provides you the
            real-time tracking of your shipment. It can be used to track any
            sort of shipment throughout Europe with information transparency and
            complete data privacy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GpsTracking;
